import * as React from "react";

import { ApiCallOptions, CustomFieldResult, DocumentType, MxtsApi, Owner, OwnerFiscalType, flatten } from "@maxxton/cms-mxts-api";
import { Widget as ApiWidget, Form, FormApi, FormResponseApi, Site, WithId } from "@maxxton/cms-api";
import { CustomerFormFields, parseCustomFields, parseCustomer } from "../../utils/customer.util";
import { FormSpec, InputSpec, localized, multiSelectStylePicker } from "../../form-specs";
import { PageWidgetSpec, Widget, isFormWidget, parseApiWidget, reportWidgetRenderError } from "../";
import { assetSpec, getHideWidgetClass, isClientLoggedIn } from "../../components/utils";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";
import { getMyEnvMainCustomer, getSelectedAccoTypeReservedResource } from "../../redux/reducers/myEnv/myEnv.util";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { RecaptchaProviderEnabler } from "../page/guestInterfaceWidget/recaptcha/reCaptcha.util";
import { SelectOption } from "../../form-specs/formSpec.types";
import { State } from "../../redux";
import { SubmitButtonOptions } from "../../components/generic-form/buttons";
import { WidgetGroup } from "../widget.enum";
import { autocompleteSiteSpec } from "../../form-specs/models/autocompleteSite";
import { findMultiSelectStyleClassNames } from "../../themes";
import { formSpec } from "../../form-specs/models";
import { getLocalizedOptions } from "../shared/revealer";
import { getMxtsEnv } from "../mxts";
import { isClientSide } from "../../utils/generic.util";
import { isPageWidget } from "../widget";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";
import { pageSpec } from "../../form-specs/models/page";
import { useSelector } from "react-redux";

const GenericForm = loadable(() => loadableRetry(() => import("../../components/generic-form/form")), {
    resolveComponent: ({ GenericForm }) => GenericForm,
});

export interface LocalizedWidgetOptions {
    locale: string;
    submitLabel: string;
    template: string;
    emailSender: string;
    emailSenderFriendlyName: string;
    emailRecipient: string;
    emailCC: string;
    emailBCC: string;
    emailSubject: string;
    customerEmailSubject: string;
    customerTemplate: string;
    feedbackMessage: string;
    siteId: string;
    pageId: string;
    enableFeedbackMethod: boolean;
    customerDataUpdatedMessage: string;
    editLabel?: string;
    cancelLabel?: string;
    file?: { document: DocumentType };
}

export interface WidgetOptions {
    _id?: string;
    formId: string | null;
    localized: LocalizedWidgetOptions[];
    showConditionCheckBox: boolean;
    styleIds: any[];
    sendEmail: boolean;
    sendFormContentToExternalUrl: boolean;
    useSmtpForSender: boolean;
    useSmtpForCustomer: boolean;
    sendReservationNumberWithEmail: boolean;
    useResortEmail: boolean;
    externalUrl: string;
    webhookToken: string;
    fields?: string;
    sendEmailToCustomer: boolean;
    customerEmailFieldId: string;
    sendMemo?: boolean;
    enableReCaptcha: boolean;
    sendDynamicDataPerEmail: boolean;
    sendDynamicDataToCustomer: boolean;
    fillContactForm?: boolean;
    updateCustomerDetails: boolean;
    sendUrlInEmail: boolean;
    recipientEmailField?: string;
    enableReadOnly: boolean;
    overrideReadOnly: boolean;
    useForOwnerPortal?: boolean;
    useForChangingUnitDetails?: boolean;
    name?: string;
}

const TARGETS = ["form", "container", "reveal-link"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "form-view-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginForm, "formWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginForm, "formWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.pluginForm, "general"),
                    properties: [
                        [
                            {
                                variable: "useForChangingUnitDetails",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "useForChangingUnitDetails"),
                            },
                            {
                                type: "reference",
                                variable: "formId",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "form"),
                                required: false,
                                refType: formSpec,
                                visible: (item: WidgetOptions) => !item.useForChangingUnitDetails,
                            },
                            {
                                variable: "fillContactForm",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "fillContactForm"),
                                visible: (item: WidgetOptions) => !item.useForChangingUnitDetails,
                            },
                            {
                                variable: "updateCustomerDetails",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "updateCustomerDetails"),
                                visible: (item: WidgetOptions) => !item.useForChangingUnitDetails,
                            },
                            {
                                variable: "overrideReadOnly",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "overrideReadOnly"),
                                visible: (item: WidgetOptions) => !item.useForChangingUnitDetails,
                            },
                            {
                                variable: "enableReadOnly",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "enableReadOnly"),
                                visible: (item: WidgetOptions) => !item.useForChangingUnitDetails,
                            },
                            multiSelectStylePicker("styleIds", TARGETS),
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        type: "text",
                                        variable: "editLabel",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "editLabel"),
                                        required: false,
                                    },
                                    {
                                        type: "text",
                                        variable: "cancelLabel",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "cancelLabel"),
                                        required: false,
                                    },
                                ],
                                visible: (item: WidgetOptions) => !!item.enableReadOnly,
                            }),
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        type: "text",
                                        variable: "submitLabel",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "submitLabel"),
                                        required: false,
                                    },
                                ],
                            }),
                            localized({
                                variable: "localized",
                                visible: (item: WidgetOptions) => item.updateCustomerDetails,
                                tabContent: [
                                    {
                                        type: "text",
                                        variable: "customerDataUpdatedMessage",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "customerDataUpdatedMessage"),
                                    },
                                ],
                            }),
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "sendMemoDescription"),
                            },
                            {
                                variable: "sendMemo",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "sendMemo"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "enableReCaptcha",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "enableReCaptcha"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "showConditionCheckBox",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "showTermsAndConditions"),
                            },
                            { ...assetSpec("assetType", "showConditionCheckBox", undefined, "file", true) },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "linking"),
                    properties: [
                        [
                            {
                                variable: "name",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "name"),
                                type: "text",
                            },
                        ],
                    ],
                    visible: (item: WidgetOptions) => !!item.useForChangingUnitDetails,
                },
                {
                    name: getI18nLocaleObject(namespaceList.pluginForm, "email"),
                    visible: (item: WidgetOptions) => !item.updateCustomerDetails,
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "smtpEnabledMessage"),
                                visible: (options: WidgetOptions) => options?.useSmtpForSender || options?.useSmtpForCustomer,
                            },
                            {
                                type: "statictabs",
                                tabs: [
                                    {
                                        name: getI18nLocaleObject(namespaceList.pluginForm, "sender"),
                                        properties: [
                                            [
                                                {
                                                    variable: "sendEmail",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "sendEmail"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "useSmtpForSender",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "useSmtp"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "sendFormContentToExternalUrl",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "sendFormContentToExternalUrl"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "sendReservationNumberWithEmail",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "sendReservationNumberWithEmail"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    type: "paragraph",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "reservationNumberInfo"),
                                                    visible: (item: WidgetOptions) => item.sendReservationNumberWithEmail,
                                                },
                                                {
                                                    variable: "useResortEmail",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "useResortEmail"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "externalUrl",
                                                    label: getI18nLocaleObject(namespaceList.admin, "externalUrl"),
                                                    type: "text",
                                                    visible: (item: WidgetOptions) => item.sendFormContentToExternalUrl,
                                                },
                                                {
                                                    variable: "sendUrlInEmail",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "sendUrlInEmail"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "recipientEmailField",
                                                    label: getI18nLocaleObject(namespaceList.admin, "recipientEmailField"),
                                                    type: "text",
                                                    visible: (item: WidgetOptions) => item.sendUrlInEmail,
                                                },
                                                {
                                                    variable: "webhookToken",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "webhookToken"),
                                                    type: "text",
                                                    visible: (item: WidgetOptions) => item.sendFormContentToExternalUrl,
                                                },
                                                {
                                                    variable: "sendDynamicDataPerEmail",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "sendDynamicDataPerEmail"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "fields",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "fields"),
                                                    type: "select",
                                                    optionList: async (item: any) => {
                                                        const form: Form = item.formId && (await FormApi.findById({ id: item.formId }));
                                                        const fieldOptions: Array<SelectOption<string>> = [];
                                                        const fieldsArray = flatten(form.elements, (parent: any) => parent.children);
                                                        fieldsArray.forEach((element) => {
                                                            element.options?.fieldId && fieldOptions.push({ value: element.options.fieldId, label: element.options.fieldId });
                                                        });
                                                        return fieldOptions;
                                                    },
                                                    required: false,
                                                },
                                                {
                                                    variable: "useForOwnerPortal",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "useForOwnerPortal"),
                                                    type: "checkbox",
                                                },
                                                localized({
                                                    variable: "localized",
                                                    tabContent: [
                                                        {
                                                            variable: "template",
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "template"),
                                                            type: "textarea",
                                                        },
                                                        {
                                                            variable: "emailSender",
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "emailSender"),
                                                            type: "text",
                                                        },
                                                        {
                                                            variable: "emailSenderFriendlyName",
                                                            // eslint-disable-next-line max-len
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "emailSenderFriendlyName"),
                                                            type: "text",
                                                        },
                                                        {
                                                            variable: "emailRecipient",
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "emailRecipient"),
                                                            type: "text",
                                                        },
                                                        {
                                                            variable: "emailCC",
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "emailCC"),
                                                            type: "text",
                                                        },
                                                        {
                                                            variable: "emailBCC",
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "emailBCC"),
                                                            type: "text",
                                                        },
                                                        {
                                                            variable: "emailSubject",
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "emailSubject"),
                                                            type: "text",
                                                        },
                                                        {
                                                            variable: "feedbackMessage",
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "feedbackMessage"),
                                                            visible: (item: any) => !item.enableFeedbackMethod,
                                                            type: "text",
                                                        },
                                                        {
                                                            variable: "enableFeedbackMethod",
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "enableFeedbackMethod"),
                                                            type: "checkbox",
                                                        },
                                                        {
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "descriptionUnitSearchExtra"),
                                                            visible: (item: any) => item.enableFeedbackMethod,
                                                            type: "paragraph",
                                                        },
                                                        {
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "redirectSite"),
                                                            variable: "siteId",
                                                            visible: (item: any) => item.enableFeedbackMethod,
                                                            type: "autocomplete",
                                                            refType: autocompleteSiteSpec,
                                                        },
                                                        {
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "redirectPage"),
                                                            variable: "pageId",
                                                            visible: (item: any) => item.enableFeedbackMethod,
                                                            type: "autocomplete",
                                                            refType: pageSpec,
                                                            dependsOnSiteSpec: "siteId",
                                                        },
                                                    ],
                                                }),
                                            ],
                                        ],
                                    },
                                    {
                                        name: getI18nLocaleObject(namespaceList.pluginForm, "customer"),
                                        properties: [
                                            [
                                                {
                                                    variable: "sendEmailToCustomer",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "sendEmailToCustomer"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "useSmtpForCustomer",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "useSmtp"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "sendDynamicDataToCustomer",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "sendDynamicDataPerEmail"),
                                                    type: "checkbox",
                                                },
                                                {
                                                    variable: "customerEmailFieldId",
                                                    label: getI18nLocaleObject(namespaceList.pluginForm, "customerEmailFieldId"),
                                                    type: "text",
                                                },
                                                localized({
                                                    variable: "localized",
                                                    tabContent: [
                                                        {
                                                            variable: "customerEmailSubject",
                                                            // eslint-disable-next-line max-len
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "customerEmailSubject"),
                                                            type: "text",
                                                        },
                                                        {
                                                            variable: "customerTemplate",
                                                            // eslint-disable-next-line max-len
                                                            label: getI18nLocaleObject(namespaceList.pluginForm, "customerTemplate"),
                                                            type: "textarea",
                                                        },
                                                    ],
                                                }),
                                            ],
                                        ],
                                    },
                                ],
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

const defaultWidgetOptions: WidgetOptions = {
    formId: null,
    styleIds: [],
    localized: [],
    customerEmailFieldId: "",
    sendEmail: false,
    sendFormContentToExternalUrl: false,
    useSmtpForSender: false,
    useSmtpForCustomer: false,
    sendReservationNumberWithEmail: false,
    useResortEmail: false,
    externalUrl: "",
    webhookToken: "",
    sendEmailToCustomer: false,
    enableReCaptcha: false,
    sendDynamicDataPerEmail: false,
    sendDynamicDataToCustomer: false,
    updateCustomerDetails: false,
    sendUrlInEmail: false,
    enableReadOnly: false,
    overrideReadOnly: false,
    showConditionCheckBox: false,
};

async function renderChildWidgets(elementWidget: Widget<any>, formViewWidgetOptions: WidgetOptions, context: CMSProvidedProperties): Promise<JSX.Element[]> {
    return Promise.all(
        elementWidget.children.map((child, index) => {
            const childSpec = child.spec;
            if (childSpec.id === "flexbox") {
                return createFlexboxFormSpecProperty(child, formViewWidgetOptions, context);
            } else if (childSpec.id === "revealer") {
                return createRevealerFormSpecProperty(child, formViewWidgetOptions, context);
            } else if (childSpec.id === "webcontent") {
                return (childSpec as any).render(child, context, undefined, undefined, undefined, false).catch((err: any) => {
                    reportWidgetRenderError(elementWidget, err, childSpec, context);
                    return <div key={index} />;
                });
            } else if (isPageWidget(childSpec)) {
                return childSpec
                    .render(child, context)
                    .then((el) => ({ el, options: child.options }))
                    .catch((err) => {
                        reportWidgetRenderError(elementWidget, err, childSpec, context);
                        return <div key={index} />;
                    });
            } else if (isFormWidget(childSpec)) {
                return (childSpec as any).toInputSpec(child, context);
            }
            throw new TypeError("Expected child widgets to be page widgets");
        })
    );
}

export const formViewWidget: PageWidgetSpec<WidgetOptions> = {
    id: "form-view",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.OTHER : 3,
    name: getI18nLocaleObject(namespaceList.pluginForm, "formWidget"),
    description: getI18nLocaleObject(namespaceList.pluginForm, "formWidgetDesc"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => defaultWidgetOptions,
    async instances(): Promise<WidgetOptions[]> {
        const forms = await FormApi.find({ projection: { _id: 1 } });
        return forms.map(
            (form: WithId): WidgetOptions => ({
                ...defaultWidgetOptions,
                formId: form._id,
            })
        );
    },
    async instanceDescription({ widget }): Promise<string> {
        const { formId, name, useForChangingUnitDetails } = widget.options;
        const form: Form | null = formId != null ? await FormApi.findById({ id: formId, projection: { name: 1 } }) : null;
        if (useForChangingUnitDetails && name) {
            return name;
        }
        if (form == null) {
            return getI18nLocaleString(namespaceList.pluginForm, "noForm");
        }
        return form.name;
    },
    // eslint-disable-next-line max-lines-per-function
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties): Promise<JSX.Element> {
        const hideWidget = getHideWidgetClass(widget.options, !isClientLoggedIn());
        const { formId, styleIds, enableReCaptcha, fillContactForm } = widget.options;
        let isReCaptchaApplied = false;
        let siteData: (Site & WithId) | null = null;
        if (hideWidget === null || formId == null || widget.options.useForOwnerPortal) {
            return <div />;
        }
        const localeOptions: LocalizedWidgetOptions | undefined = (widget.options.localized || []).find((l) => l.locale === context.currentLocale.locale);

        const form: Form | null = await context.cmsApi.formApi.findById({ id: formId });
        if (form == null) {
            throw new Error("form cannot be null");
        }
        if (enableReCaptcha && isClientSide()) {
            const hostName = window.location.href.split("/");
            siteData = await context.cmsApi.siteApi.findByHost({ host: hostName[2], projection: { sitemap: 0 } });
            if (siteData && siteData.reCaptchaSiteKey) {
                isReCaptchaApplied = true;
            }
        }
        const selectedReservation = context.reduxStore?.store?.getState()?.myEnvState?.selectedReservation;
        const env = await getMxtsEnv(context);
        let customFieldsList: CustomFieldResult[] = [];
        const hasCustomFields = flatten(form?.elements || [], (parent: any) => parent.children).some((formWidget) => formWidget?.options?.customFieldOptions?.length);
        const selectedAccoTypeReservedResource = getSelectedAccoTypeReservedResource(selectedReservation);

        if (hasCustomFields && selectedReservation?.reservation?.reservationId && selectedAccoTypeReservedResource?.reservedResourceId) {
            customFieldsList = await MxtsApi.getCustomFields(env, { sort: "priority,ASC" }, [
                { key: "reservationId", value: selectedReservation?.reservation?.reservationId },
                { key: "reservedResourceId", value: selectedAccoTypeReservedResource.reservedResourceId },
            ]);
        }

        const properties: Array<InputSpec<any, string>> = await Promise.all(
            form.elements
                .filter((item) => item !== null)
                .map(
                    async (element: ApiWidget, index): Promise<InputSpec<any, string>> => {
                        const elementWidget: Widget<any> = await parseApiWidget(element, context);
                        const spec = elementWidget.spec;
                        if (isFormWidget(spec)) {
                            return spec.toInputSpec(elementWidget, context);
                        } else if (isPageWidget(spec)) {
                            if (spec.id === "flexbox") {
                                return createFlexboxFormSpecProperty(elementWidget, widget.options, context);
                            } else if (spec.id === "revealer") {
                                return createRevealerFormSpecProperty(elementWidget, widget.options, context);
                            } else if (spec.id === "webcontent") {
                                return spec.render(elementWidget, context, undefined, undefined, undefined, true).catch((err) => {
                                    reportWidgetRenderError(widget, err, spec, context);
                                    return <div key={index} />;
                                });
                            }
                            throw new Error("Only Container widgets, webcontent or form widgets are allowed");
                        } else {
                            throw new Error("Form elements should be form widgets");
                        }
                    }
                )
        );
        const dynamicFormSpec: FormSpec<any> = {
            id: formId,
            name: (form.name as any) as string,
            pluralName: (form.name as any) as string,
            properties: properties as any,
        };

        function saveForm(newVal: any, options?: SubmitButtonOptions | undefined, token?: string) {
            if (token) {
                FormResponseApi.createWithReCaptcha({
                    item: {
                        form: form as Form,
                        response: newVal,
                        token,
                        host: siteData?.host,
                    },
                }).then((result) => {
                    // Note:- Logging response to check what are the data being stored in the form response table.
                    console.info("Stored form response: ", result.response);
                });
            } else {
                FormResponseApi.create({
                    item: {
                        form: form as Form,
                        response: newVal,
                    },
                }).then((result) => {
                    // Note:- Logging response to check what are the data being stored in the form response table.
                    console.info("Stored form response: ", result.response);
                });
            }
        }
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        const initialContractFormData = fillContactForm ? await getContactFormData(context, env, customFieldsList) : {};
        return (
            <GenericFormContainer
                {...{ className, initialContractFormData, hideWidget, dynamicFormSpec, localeOptions, env, saveForm, widget, context, customFieldsList, isReCaptchaApplied }}
            ></GenericFormContainer>
        );
    },
};

export function GenericFormContainer(props: {
    className: string;
    initialContractFormData: CustomerFormFields;
    hideWidget: string;
    env: ApiCallOptions;
    saveForm: (newVal: any, options?: SubmitButtonOptions, token?: string) => Promise<string> | void;
    dynamicFormSpec: FormSpec<any>;
    localeOptions: LocalizedWidgetOptions | undefined;
    customFieldsList: CustomFieldResult[];
    widget: Widget<WidgetOptions>;
    context: CMSProvidedProperties;
    isReCaptchaApplied: boolean;
}) {
    const { className, hideWidget, dynamicFormSpec, localeOptions, initialContractFormData, env, saveForm, widget, context, customFieldsList, isReCaptchaApplied } = props;
    const { fillContactForm } = widget.options;

    const [contractFormData, setContractFormData] = React.useState<CustomerFormFields>(initialContractFormData);
    const mainCustomer = useSelector((state: State) => state.myEnvState.mainCustomer);

    React.useEffect(() => {
        const updateContractFormData = async () => setContractFormData(fillContactForm ? await getContactFormData(context, env, customFieldsList) : {});
        updateContractFormData();
    }, [mainCustomer]);

    const genericForm = (
        <GenericForm
            className={`${className} ${hideWidget}`}
            mode="edit"
            buttons="inline"
            spec={dynamicFormSpec}
            value={contractFormData}
            enableCancel={false}
            enableDelete={false}
            enableSubmit={true}
            localizedWidgetOptions={localeOptions}
            onSave={saveForm}
            widgetOptions={widget.options}
            shouldDisableSubmit={false}
            alerts={context.alerts}
            isReCaptchaApplied={isReCaptchaApplied}
            context={context}
            customFieldsList={customFieldsList}
        />
    );
    return isReCaptchaApplied ? <RecaptchaProviderEnabler>{genericForm}</RecaptchaProviderEnabler> : genericForm;
}

export async function createRevealerFormSpecProperty(elementWidget: Widget<any>, formViewWidgetOptions: WidgetOptions, context: CMSProvidedProperties): Promise<InputSpec<any, string>> {
    const spec = elementWidget.spec;
    const children: JSX.Element[] = await renderChildWidgets(elementWidget, formViewWidgetOptions, context);
    const localeContent = getLocalizedOptions(context, elementWidget.options);
    const showLess = localeContent && localeContent.showLess ? localeContent.showLess.label : "";
    const showMore = localeContent && localeContent.showMore ? localeContent.showMore.label : "";
    const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, formViewWidgetOptions.styleIds);
    return {
        spec,
        id: elementWidget._id,
        childs: children,
        options: elementWidget.options,
        showLess,
        showMore,
        className: styleClasses,
    } as any;
}

export async function createFlexboxFormSpecProperty(elementWidget: Widget<any>, formViewWidgetOptions: WidgetOptions, context: CMSProvidedProperties): Promise<InputSpec<any, string>> {
    const spec = elementWidget.spec;
    const children: JSX.Element[] = await renderChildWidgets(elementWidget, formViewWidgetOptions, context);
    const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, elementWidget.options.styleIds);
    return {
        spec,
        options: elementWidget.options,
        children,
        className: styleClasses,
        left: "<",
        right: ">",
        id: elementWidget._id,
    } as any;
}

async function getContactFormData(context: CMSProvidedProperties, env: ApiCallOptions, customFieldsList?: CustomFieldResult[]): Promise<CustomerFormFields> {
    const customerData = await getMyEnvMainCustomer(env);
    let contactFormData = {};
    if (customerData) {
        let ownerFiscalType: OwnerFiscalType | undefined;
        let owner: Owner | undefined;
        if (customerData.owner) {
            const [obtainedOwner, ownerFiscalTypes] = await Promise.all([
                context.mxtsApi.getOwner(env, {}, [{ key: "ownerId", value: customerData?.customerId }]).catch(() => undefined),
                context.mxtsApi.getOwnerFiscalTypes(env, { isActive: true }, [{ key: "ownerId", value: customerData?.customerId }]).catch(() => undefined),
            ]);
            ownerFiscalType = ownerFiscalTypes?.content?.[0];
            owner = obtainedOwner;
        }
        const parsedCustomerData = parseCustomer(customerData, ownerFiscalType, owner);
        contactFormData = { ...contactFormData, ...parsedCustomerData };
    }

    if (customFieldsList?.length) {
        const parsedCustomFields = parseCustomFields(customFieldsList);
        parsedCustomFields.forEach((parsedCustomField) => {
            contactFormData = { ...contactFormData, ...parsedCustomField };
        });
    }
    return contactFormData;
}
